import { FieldId, Locale } from "../lib";
import { ACTION_TYPE, Action, State, TranslationRequest } from "./types";

const toggleLocaleInLocalesArray = (targetLocales: Locale[], toLocale: Locale): Locale[] => {
    const localeIndex = targetLocales.indexOf(toLocale);

    if (localeIndex !== -1) {
        return targetLocales.filter((_, index) => index !== localeIndex);
    } else {
        return [...targetLocales, toLocale];
    }
};

const toggleAllLocalesInLocalesArray = (
    targetLocales: Locale[],
    toLocales: Locale[],
    toggle: "add" | "remove"
): Locale[] => {
    if (toggle === "add") {
        return Array.from(new Set([...targetLocales, ...toLocales]));
    } else {
        return targetLocales.filter(targetLocale => !toLocales.includes(targetLocale));
    }
};

const findTranslationRequestIndex = (
    translationRequests: TranslationRequest[],
    fieldId: FieldId
) => {
    return translationRequests.findIndex(translation => translation.fieldId === fieldId);
};

const setToLocalesFromPreviousToLocales = (
    previousTranslationRequests: TranslationRequest[],
    targetLocales: Locale[],
    fieldId: string
) => {
    if (previousTranslationRequests.length === 0) {
        return targetLocales;
    }

    const index = findTranslationRequestIndex(previousTranslationRequests, fieldId);

    if (index === -1) {
        return previousTranslationRequests[0].to.filter(toLocale =>
            targetLocales.includes(toLocale)
        );
    }

    return previousTranslationRequests[index].to.filter(toLocale =>
        targetLocales.includes(toLocale)
    );
};

const updateTranslationRequest = (
    index: number,
    translationRequests: TranslationRequest[],
    updatedTranslationRequest: Partial<TranslationRequest>
) => {
    return [
        ...translationRequests.slice(0, index),
        {
            ...translationRequests[index],
            ...updatedTranslationRequest,
        },
        ...translationRequests.slice(index + 1),
    ];
};

export const translationReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ACTION_TYPE.SET_TRANSLATION_FROM: {
            const { fieldId, fromLocale } = action.payload;
            const index = findTranslationRequestIndex(state.translationRequests, fieldId);

            if (index === -1 || state.translationRequests[index].from === fromLocale) {
                return state;
            }

            return {
                ...state,
                translationRequests: updateTranslationRequest(index, state.translationRequests, {
                    from: fromLocale,
                }),
            };
        }
        case ACTION_TYPE.SET_TRANSLATION_TO: {
            const { fieldId, toLocale } = action.payload;
            const index = findTranslationRequestIndex(state.translationRequests, fieldId);

            const updatedToLocales = toggleLocaleInLocalesArray(
                state.translationRequests[index].to,
                toLocale
            );

            return {
                ...state,
                lastChangedTranslationFieldId: fieldId,
                translationRequests: updateTranslationRequest(index, state.translationRequests, {
                    to: updatedToLocales,
                }),
            };
        }
        case ACTION_TYPE.SET_ALL_TRANSLATION_TO: {
            const { fieldId, toLocales, toggle } = action.payload;
            const index = findTranslationRequestIndex(state.translationRequests, fieldId);

            const updatedToLocales = toggleAllLocalesInLocalesArray(
                state.translationRequests[index].to,
                toLocales,
                toggle
            );

            return {
                ...state,
                lastChangedTranslationFieldId: fieldId,
                translationRequests: updateTranslationRequest(index, state.translationRequests, {
                    to: updatedToLocales,
                }),
            };
        }
        case ACTION_TYPE.SET_FORMALITY: {
            const { fieldId, formality } = action.payload;
            const index = findTranslationRequestIndex(state.translationRequests, fieldId);

            return {
                ...state,
                translationRequests: updateTranslationRequest(index, state.translationRequests, {
                    formality: formality,
                }),
            };
        }
        case ACTION_TYPE.SET_TRANSLATION_REQUESTS: {
            localStorage.setItem(
                "previousTranslationRequests",
                JSON.stringify(state.translationRequests)
            );

            return {
                ...state,
                translationRequests: action.payload,
                lastChangedTranslationFieldId: null,
            };
        }
        case ACTION_TYPE.SET_HAS_FIELD_CHANGED: {
            return {
                ...state,
                hasFieldChanged: action.payload,
            };
        }
        case ACTION_TYPE.HANDLE_FIELD_CHANGE: {
            const { field, formality, locale, targetLocales, translationRequests, value } =
                action.payload;
            const { lastChangedTranslationFieldId } = state;

            const index = findTranslationRequestIndex(translationRequests, field.id);

            let toLocales: Locale[];

            if (translationRequests[index]) {
                toLocales =
                    translationRequests[index].to.length !== targetLocales.length
                        ? translationRequests[index].to
                        : targetLocales;
            } else if (lastChangedTranslationFieldId !== null) {
                const lastChangedTranslationIndex = findTranslationRequestIndex(
                    translationRequests,
                    lastChangedTranslationFieldId
                );
                toLocales = translationRequests[lastChangedTranslationIndex].to;
            } else {
                const previousTranslationRequests = localStorage.getItem(
                    "previousTranslationRequests"
                );

                toLocales = setToLocalesFromPreviousToLocales(
                    previousTranslationRequests ? JSON.parse(previousTranslationRequests) : [],
                    targetLocales,
                    field.id
                );
            }

            const translationEntry = {
                fieldId: field.id,
                formality,
                from: locale,
                label: field.label,
                to: toLocales,
                value: value,
            };

            let updatedTranslationRequests = [...translationRequests];

            if (index !== -1) {
                updatedTranslationRequests[index] = translationEntry;
            } else {
                updatedTranslationRequests = [...translationRequests, translationEntry];
            }
            return {
                ...state,
                translationRequests: updatedTranslationRequests,
            };
        }
        default:
            return state;
    }
};
